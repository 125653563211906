<template>
  <div class="d-flex flex-row">
    <div
      class="flex-row-auto offcanvas-mobile w-300px w-xl-350px"
      id="kt_profile_aside"
    >
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex justify-content-end">
          </div>
          <div class="navi navi-bold navi-hover navi-active navi-link-rounded" role="tablist" >          
          </div>
        </div>
      </div>
    </div>

    <div v-if="this.showCurrentTask" class="flex-row-fluid ml-lg-8">
      <div class="card card-custom">
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">New Email Changed Successfully, Login with New Email</h3>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!this.showCurrentTask" class="flex-row-fluid ml-lg-8">
      <div class="card card-custom">
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">Error Occured, Re-try Change Email Again</h3>
          </div>
        </div>
      </div>
    </div>

	<div v-if="!this.showCurrentTask" class="flex-row-fluid ml-lg-8">
    <span> change email link expired/errpr  </span>      
	</div>
  </div>
</template>

<script>

import { VERIFY_CHANGE_EMAIL } from "@/core/services/store/auth.module";
import { LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";


export default {
  name: "custom-page",
  components: {
  },
  data() {
    return {
      tabIndex: 0,
      user: '',
      passwordId:'',
      showCurrentTask: false,
    };
  },
  beforeMount(){
    this.getUnits()
 },
 
  methods: {
    /**
     * Set current active on click
     * @param event
     */
      getUnits: function () {
      var password=this.$route.params.password;
  
      this.$store
        .dispatch(VERIFY_CHANGE_EMAIL, {password})
        .then(response=> {
          if(response.success){
            this.showCurrentTask	= true;
            
            Swal.fire({title: "New email changed successfully",confirmButtonClass: "btn btn-secondary"})
                .then(() => { 
                  this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: "login" }));
                });
          }else{
            this.showCurrentTask = false
            Swal.fire({title: "Error occured, Re-try change email again",confirmButtonClass: "btn btn-secondary"})
                .then(() => { 
                  this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: "login" }));
                });
          }
          
          
        
        })
        .catch(() => {
          console.error("dispatch error")
        });
  }    
  
  },
};
</script>
